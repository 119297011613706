.cards {
    
    background: #fff;
    padding-top: 7.5em!important;
    
    
  }

  .responsive-logo {
    display:none!important;
  }


  .img-card {

    margin-left: 11%!important;

  }

  .card-logo-grid {
    padding-bottom: 1em!important;
  }
  
  h1 {
    text-align: center;
    font-style: oblique;
    color:#565656;
    font-size: 70px;
    font: normal normal 600 70px/98px Marr Sans Cond;
    margin: 0 auto;
  }
  
  .cards__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 90%;
    margin: 0 auto;
    margin-top:3em;
  }
  
  .cards__wrapper {
    position: relative;
    margin: 50px 0 45px;
  }
  
  .cards__items {
    margin-bottom: 24px;
  }
  
  .cards__item {
    display: flex;
    flex: 1;
    margin: 0 1rem;
    border-radius: 10px;
  }
  
  .cards__item__link {
    display: flex;
    flex-flow: column;
    width: 100%;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
  }
  
  .cards__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
  }
  
  .fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
  }
  
  .cards__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    background-color: #1f98f4;
    box-sizing: border-box;
  }
  
  .cards__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
  }
  
  .cards__item__img:hover {
    transform: scale(1.1);
  }
  
  .cards__item__info {
    padding: 20px 30px 30px;
  }
  
  .cards__item__text {
    color: #252e48;
    font-size: 18px;
    line-height: 24px;
  }

  
  .cards p {
    padding-bottom: 0%;
    text-align:center; 
    font-size: 1.5em;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  .cards .col-style-s {

    text-align:center!important;
    display: flex!important;
    justify-content: center!important;
    align-items: center!important;
  }

  .rows-sec {

    margin-left: 33%!important;
    margin-bottom: 2em!important;

  }
  .card-logo {
   
    
    width: 6.813em;
    height: 5.813em;
    opacity: 1;
    

  }

  .cards p.sub-text {
    margin: 0 auto;
    color:#707070;
    font-family: 'Roboto';
    width:70%!important;
    
  }

  .title-h1 {

    font-family: 'Fjalla One';
    font-weight: bold;
  }

  .cards-bodys {
    
    color:white!important;
    text-align:center!important;
    display: flex!important;
    padding-left: 15px!important;
    padding-right: 15px!important;
    padding-top: 1em!important;
    padding-bottom: 1em!important;
  }

  

  .card-styles {
    background-color: #523BE4!important;
    color : #fff!important;
    border-radius: 4px!important;
    opacity: 1!important;
   
    text-align:center!important; 
    vertical-align: middle!important;

    padding-top: 1.5em!important;
    padding-bottom: 1.5em!important;
   
  }

  .card-style-double {

    border-radius: 4px!important;
    opacity: 1!important;
    text-align:center!important; 
    vertical-align: middle!important;
    padding-top: 2%!important;
    margin: 2em!important;
    width: 614px!important;
    height: 501px!important;
  }

  .cards span.text-color {
      color:#523BE4;
      
  }

  .padding-text {
    margin-top: 30px;
    padding-left: 25px;
  }

  .cards p.titulos {
    margin: 0 auto;
    color:#565656;
    text-align: left;
    font-family: Fjalla One!important;
    font: normal normal 600 24px/40px Fjalla One;
  }
  
  .cards p.titulos- {
    margin-bottom: 1em!important;
    font-family: Fjalla One!important;
    font: normal normal 600 28px/43px Fjalla One;
    color:#565656;
  }
  .cards p.subtitulo {
    
    font-family: Fjalla One!important;
    margin: 0 auto;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-23)/var(--unnamed-line-spacing-24) var(--unnamed-font-family-marr-sans-cond);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-707070);
    text-align: left;
    font: normal normal 600 23px/24px Fjalla One;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
    margin-bottom: 1em!important;
    margin-top: 1em!important;
  }

  .cards p.cuerpo {

    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 17px/25px var(--unnamed-font-family-roboto);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-707070);
    text-align: left;
    font: normal normal normal 18px/1.5625em Roboto;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
    text-align: justify;
    margin-bottom: 1em!important;
  }
  
  .cards .container-boot{

    margin-bottom: 5em!important;
    
   
  }

  .padding-col{

    margin-left: 1.5em!important;
  }

  .cards .colPadding{
    margin-top: 7.5em!important;
    
  }

  .grid-logo {

    display: grid;
    justify-content: center!important;
  }

 
  @media only screen and (min-width: 1200px) {
    .content__blog__container {
      width: 84%!important;
    }
    
  }
  
  @media only screen and (min-width: 1024px) {
    .card-style {
      display: flex!important;
    }

    
    
  }
  
  @media only screen and (max-width: 1024px) {
    .card-style {
      margin-bottom: 2rem!important;
      
    
    }

    .rows-sec {

      
      margin-left: 6%!important;
  
    }


    @media only screen and (max-width: 600px) {
      .container-card {
        display: flex!important;
        flex-direction: column-reverse!important;
        
        
      }

      .row-dos {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 0px!important;
      }


      .container-cards {
   
        display: flex!important;
        flex-direction: column!important;
    
      }
      
    }

    .flex-center {

      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 0px!important;
    }
    

    
    
  }



  .container-card {
   
    display : grid;
    grid-template-columns: 50% 50%;

  }

  .container-cards {
   
    display : grid;
    grid-template-columns: 50% 50%;

  }


  @media screen and (min-device-width: 768px) and (max-device-width: 1024px) {

    .cards {
    
      background: #fff;
      padding-top: 3.75em!important;

    }


    .sub-text {

      margin-left: 5%!important;
      margin-right: 5%!important;
    }

    .cards-bodys {

      font: normal normal bold 16px/19px Roboto!important;
    }

    .padding-card-healt {

      padding-bottom:  0.5em!important;
      padding-left: 1em!important;
     
      
    }

    .cards p.titulos {
      margin: 0 auto;
      color:#565656;
      text-align: left;
      font-family: Fjalla One!important;
      font: normal normal 600 20px Fjalla One;
    }



    .cards p.subtitulo {
    
      font-family: Fjalla One!important;
      margin: 0 auto;
      font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-23)/var(--unnamed-line-spacing-24) var(--unnamed-font-family-marr-sans-cond);
      letter-spacing: var(--unnamed-character-spacing-0);
      color: var(--unnamed-color-707070);
      text-align: left;
      font: normal normal 600 19px/24px Fjalla One;
      letter-spacing: 0px;
      color: #707070;
      opacity: 1;
      margin-bottom: 0.5em!important;
      margin-top: 0.5em!important;
    }
  
    .cards p.cuerpo {
  
      font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 17px/25px var(--unnamed-font-family-roboto);
      letter-spacing: var(--unnamed-character-spacing-0);
      color: var(--unnamed-color-707070);
      text-align: left;
      font: normal normal normal 14px/1.5625em Roboto;
      letter-spacing: 0px;
      color: #707070;
      opacity: 1;
      
      margin-bottom: 1em!important;
    }


    .card-styles {
      background-color: #523BE4!important;
      color : #fff!important;
      border-radius: 4px!important;
      opacity: 1!important;
     
      text-align:center!important; 
      vertical-align: middle!important;
  
      padding-top: 0.5em!important;
      padding-bottom: 0.5em!important;
     
    }

    .data-cards {
      

      width:90%!important;
      height:80%!important;
    }


    .cards .colPadding{
      margin-top: 3.7em!important;
      
    }
    
    .padding-text {
      margin-top: 10px;
      padding-left: 25px;
    }
    
    
 }


 @media only screen and (max-width: 320px) {
  
  .grid-logo {

    display: grid;
    justify-content: center!important;
    padding-bottom: 15px!important;

  }

  img .responsive-logo {

    width: 52px !important;
    height: 44px !important;
  }

  .card-logo-grid {
    padding-bottom: 0em!important;
  }

  .responsive-logo {
    display:initial!important;
  }

  .non-responsive-logo {
    display:none!important;
  }
  
  .title-h1 {

  
    margin-bottom: 30px;
    margin-left: 60px;
    margin-right: 60px;
    font: normal normal bold 28px/34px 'Fjalla One';
  }

  .cards p.sub-text {
    margin: 0 auto;
    color:#707070;
    font-family: 'Roboto';
    font-size: 18px!important;
    
    
  }

  .cards-bodys {
    
    color:white!important;
    text-align:center!important;
    display: flex!important;
    padding-left: 15px!important;
    padding-top: 1em!important;
    padding-bottom: 1em!important;
    font-size: 18px!important;
  }

  .cards__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 80%;
    margin: 0 auto;
    margin-top:32px!important;
  }

  .padding-card-healt {
    padding-bottom:  0em!important;
    width:25%;
    height:25%;
    text-align: center!important;
    justify-content: center!important;
    align-items: center!important;
   
  }

  .img-card {

    margin-left: 10%!important;
    height: 70px!important;
    

  }

  .card-styles {

    margin-bottom: 40px!important;
  }

  .cards p.titulos {
    margin: 0 auto;
    color:#565656;
    text-align: center;
    font-family: Fjalla One!important;
    font: normal normal 600 24px/30px Fjalla One;
  }

  .cards p.subtitulo {
    
    font-family: Fjalla One!important;
    margin: 0 auto;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-23)/var(--unnamed-line-spacing-24) var(--unnamed-font-family-marr-sans-cond);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-707070);
    text-align: center;
    font: normal normal 600 20px/30px Fjalla One;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
   
  }


  .cards p.cuerpo {
  
    
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-707070);
    text-align: center;
    font: normal normal normal 17px/25px Roboto;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
    margin-bottom: 0px!important;
    
  }

  .padding-text {
    padding-left: 0px!important;
    margin-top: 0px!important;
  }

  .padding-col {
    margin-left: 0px!important;
  }


  .cards .colPadding{
    margin-top: 80px!important;
    margin-bottom: 0px!important;
    
  }

  .cards__wrapper {
    margin-bottom: 120px!important;
  }
  


  .cards .col_padding{
    margin-top: 80px!important;
    margin-bottom: 0px!important;
    
  }


 
 }







