.footer-container {
  background-color: #b31b1b;
  padding: 0 0 20px 0;
    

 
}




.footer-links {
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: center;
}

.footer-link-wrapper {
  display: flex;
}

.footer-link-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  text-align: left;
  width: 160px;
  box-sizing: border-box;
}

.footer-link-items h2 {
  margin-bottom: 16px;
}

.footer-link-items > h2 {
  color: #fff;
}

.footer-link-items a {
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
}

.footer-link-items a:hover {
  color: #e9e9e9;
  transition: 0.3s ease-out;
}

.footer-email-form h2 {
  margin-bottom: 2rem;
}

.footer-input::placeholder {
  color: #b1b1b1;
}

/* Social Icons */
.social-icon-link {
  color: #fff;
  font-size: 24px;
  margin: 10px 10px;
  
}

.social-media {
  max-width: 1000px;
  width: 100%;
}

.social-media-wrap {
  display: flex;
  
  align-items: center;
  width: 100%;
  margin: 40px auto 0 auto;
  background-color: mediumorchid;
}




 .izei-aviso {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 17px/23px var(--unnamed-font-family-roboto);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-523be4);
    text-align: left;
    font: normal normal normal 0.8em Roboto;
    letter-spacing: 0px;
    color: #523BE4; 

 }
.website-rights {
  color: #fff;
  margin: 0 0 0!important;
  font-size: 1em 'Roboto'!important;
}


.img-fluid-izei {
  width:120px;
  height:37.95px;
  
}





.grid-footer {

  display:grid;
  grid-template-columns: 1fr 1fr;
  height: 150px;
  background: #000000 0% 0% no-repeat padding-box;
  opacity: 1;
}

.logo-footer {
  padding-left: 18%;
  margin-top: 0.8em!important;
  
}

.navigate-icons {
  
  display: flex;
  width:auto;
  justify-content: flex-end;
  margin-right: 16%;
  margin-top: 0.8em!important;

  
}

.navigate-logo {
  
    width: 120px;
    height: 62px;
    background: transparent  0% 0% no-repeat padding-box;
    opacity: 1;
    display: flex;
    align-items: center;
    text-decoration: none;
}


@media screen and (max-width: 1920px){
  .btn-2em{
    display:none !important;
  }
}

@media screen and (max-width: 600px) {

  
  .grid-footer {

    display:grid;
    grid-template-columns: 1fr;
    height: 190px;
    
  }

  .img-fluid-izei {
    width:28%!important;
    height:43%!important;
    
    
  }

  
  .logo-footer {
    margin:none;
    padding:0!important;
    
  }
  .navigate-icons {
    margin:auto;
    justify-content: center;
    
  }

  .izei-aviso {
    display:flex;
    justify-content: center!important;
  }

  .website-rights {
    display:flex;
    justify-content: center!important;
  }

  .navigate-logo {
    width:auto;
    display:flex;
    justify-content: center!important;
  }
  
  
  
  

  


}